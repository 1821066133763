import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="calendar"
export default class extends Controller {
    static targets = [ "scrollArea" ]

    changeMonth(event) {
        let month = event.target.value
        let element = document.getElementById(month)
        window.scrollTo(0, element.getBoundingClientRect().top + window.scrollY - 70)
    }

    changeMonthOnModal(event) {
        let month = event.target.value
        let element = document.getElementById(month)
        this.scrollAreaTarget.scrollTo(0, element.getBoundingClientRect().top + window.scrollY - 50)
    }
}
