import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="book"
export default class extends Controller {
  static targets = [ "coursesFrame", "insurerCardFrame" ]

  changeOrganization(event) {
    const organizationCode = event.target.dataset.organizationCode;
    const coursesFrame = this.coursesFrameTarget;
    const coursesUrl = new URL(coursesFrame.dataset.frameUrl);
    coursesUrl.searchParams.set('organization_code', organizationCode);
    coursesFrame.src = coursesUrl;

    const insurerCardFrame = this.insurerCardFrameTarget;
    const insurerCardUrl = new URL(insurerCardFrame.dataset.frameUrl);
    insurerCardUrl.searchParams.set('organization_code', organizationCode);
    insurerCardFrame.src = insurerCardUrl;
  }
}
