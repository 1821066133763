import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="side-book"
export default class extends Controller {
  static targets = [ "currentBookId", "prev", "next" ]

  connect() {
    const id = this.currentBookIdTarget.dataset.id;
    const book_ids = this.indexBookIds();
    if (book_ids.indexOf(id) === 0) {
      this.prevTarget.classList.add('disabled')
    } else {
      this.prevTarget.classList.remove('disabled')
    }
    if (book_ids.indexOf(id) === book_ids.length - 1) {
      this.nextTarget.classList.add('disabled')
    } else {
      this.nextTarget.classList.remove('disabled')
    }
  }

  prevBook() {
    const book_ids = this.indexBookIds();
    if (book_ids.length === 0) return false;
    const next_id = book_ids[book_ids.indexOf(this.currentBookIdTarget.dataset.id) - 1]
    this.accessBook(next_id)
  }

  nextBook() {
    const book_ids = this.indexBookIds();
    if (book_ids.length === 0) return false;
    const next_id = book_ids[book_ids.indexOf(this.currentBookIdTarget.dataset.id) + 1]
    this.accessBook(next_id)
  }

  indexBookIds() {
    let book_ids = document.getElementById('index_book_ids')
    return book_ids.dataset.ids.split(',');
  }

  activateSideBookLink() {
    const book_ids = this.indexBookIds();
  }

  accessBook(book_id) {
    const modal = document.querySelector('#modal_main')
    const url = location.href.split('?')[0]
    modal.src = url + `/${book_id}`
  }
}
