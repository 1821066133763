import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="corp-book"
export default class extends Controller {
  static targets = [ "organization", "insuranceCardKenpoArea", "insurerName", "insurerNameDisplay" ]

  connect() {
    this.setInsuranceCard(this.organizationTarget.value)
  }

  changeOrganization(event) {
    this.setInsuranceCard(event.target.value)
  }

  setInsuranceCard(status) {
    this.insuranceCardKenpoAreaTarget.style.display = (status === 'kenpo' ? 'flex' : 'none')
  }

  setInsurerName(event) {
    let selected = event.target.value
    let insurerName = this.insurerNameTarget
    let insurerNameDisplay = this.insurerNameDisplayTarget
    if (selected === undefined || selected.length === 0) {
      insurerName.value = ''
      insurerNameDisplay.value = ''
      return;
    }

    Array.from(insurerName.options).forEach(function(elm) {
      if (elm.value.match(selected.split(' ')[0])) {
        insurerName.value = elm.value
        insurerNameDisplay.value = elm.value
        return false;
      }
    })
  }
}
