import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="plan-report"
export default class extends Controller {
  static targets = [ "startMonth", "endMonth", "errorMessage", "form" ]

  connect() {
    this.errorMessageTarget.hidden = true;
  }

  print() {
    this.errorMessageTarget.hidden = true;
    this.startMonthTarget.classList.remove('is-invalid')
    this.endMonthTarget.classList.remove('is-invalid')
    let error = ""

    if (this.startMonthTarget.value.length === 0) {
      this.startMonthTarget.classList.add('is-invalid')
      error = '開始月を選択してください'
    }
    if (this.endMonthTarget.value.length === 0) {
      this.endMonthTarget.classList.add('is-invalid')
      if (error.length === 0) {
        error = '終了月を選択してください'
      } else {
        error = '開始月と終了月を選択してください'
      }
    }
    if (error === '' && this.startMonthTarget.value > this.endMonthTarget.value) {
      this.endMonthTarget.classList.add('is-invalid')
      error = '開始月と終了月の範囲が不正です'
    }
    if (error.length > 0) {
      this.errorMessageTarget.hidden = false;
      this.errorMessageTarget.innerHTML = error
    } else {
      this.formTarget.submit()
    }

  }
}
